// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-docs-docs-architecture-overview-md": () => import("./../../src/docs/docs/architecture-overview.md" /* webpackChunkName: "component---src-docs-docs-architecture-overview-md" */),
  "component---src-docs-docs-array-types-mdx": () => import("./../../src/docs/docs/array-types.mdx" /* webpackChunkName: "component---src-docs-docs-array-types-mdx" */),
  "component---src-docs-docs-class-types-mdx": () => import("./../../src/docs/docs/class-types.mdx" /* webpackChunkName: "component---src-docs-docs-class-types-mdx" */),
  "component---src-docs-docs-configuration-mdx": () => import("./../../src/docs/docs/configuration.mdx" /* webpackChunkName: "component---src-docs-docs-configuration-mdx" */),
  "component---src-docs-docs-editor-plugins-mdx": () => import("./../../src/docs/docs/editor-plugins.mdx" /* webpackChunkName: "component---src-docs-docs-editor-plugins-mdx" */),
  "component---src-docs-docs-function-types-mdx": () => import("./../../src/docs/docs/function-types.mdx" /* webpackChunkName: "component---src-docs-docs-function-types-mdx" */),
  "component---src-docs-docs-generic-types-mdx": () => import("./../../src/docs/docs/generic-types.mdx" /* webpackChunkName: "component---src-docs-docs-generic-types-mdx" */),
  "component---src-docs-docs-installation-mdx": () => import("./../../src/docs/docs/installation.mdx" /* webpackChunkName: "component---src-docs-docs-installation-mdx" */),
  "component---src-docs-docs-libraries-mdx": () => import("./../../src/docs/docs/libraries.mdx" /* webpackChunkName: "component---src-docs-docs-libraries-mdx" */),
  "component---src-docs-docs-magic-types-mdx": () => import("./../../src/docs/docs/magic-types.mdx" /* webpackChunkName: "component---src-docs-docs-magic-types-mdx" */),
  "component---src-docs-docs-modules-mdx": () => import("./../../src/docs/docs/modules.mdx" /* webpackChunkName: "component---src-docs-docs-modules-mdx" */),
  "component---src-docs-docs-object-types-mdx": () => import("./../../src/docs/docs/object-types.mdx" /* webpackChunkName: "component---src-docs-docs-object-types-mdx" */),
  "component---src-docs-docs-optional-types-mdx": () => import("./../../src/docs/docs/optional-types.mdx" /* webpackChunkName: "component---src-docs-docs-optional-types-mdx" */),
  "component---src-docs-docs-primitive-types-mdx": () => import("./../../src/docs/docs/primitive-types.mdx" /* webpackChunkName: "component---src-docs-docs-primitive-types-mdx" */),
  "component---src-docs-docs-tuple-types-mdx": () => import("./../../src/docs/docs/tuple-types.mdx" /* webpackChunkName: "component---src-docs-docs-tuple-types-mdx" */),
  "component---src-docs-docs-type-aliases-mdx": () => import("./../../src/docs/docs/type-aliases.mdx" /* webpackChunkName: "component---src-docs-docs-type-aliases-mdx" */),
  "component---src-docs-docs-type-annotations-mdx": () => import("./../../src/docs/docs/type-annotations.mdx" /* webpackChunkName: "component---src-docs-docs-type-annotations-mdx" */),
  "component---src-docs-docs-type-compatibility-mdx": () => import("./../../src/docs/docs/type-compatibility.mdx" /* webpackChunkName: "component---src-docs-docs-type-compatibility-mdx" */),
  "component---src-docs-docs-type-inference-mdx": () => import("./../../src/docs/docs/type-inference.mdx" /* webpackChunkName: "component---src-docs-docs-type-inference-mdx" */),
  "component---src-docs-docs-type-refinement-mdx": () => import("./../../src/docs/docs/type-refinement.mdx" /* webpackChunkName: "component---src-docs-docs-type-refinement-mdx" */),
  "component---src-docs-docs-union-types-mdx": () => import("./../../src/docs/docs/union-types.mdx" /* webpackChunkName: "component---src-docs-docs-union-types-mdx" */),
  "component---src-docs-docs-unknown-type-mdx": () => import("./../../src/docs/docs/unknown-type.mdx" /* webpackChunkName: "component---src-docs-docs-unknown-type-mdx" */),
  "component---src-docs-docs-what-and-why-mdx": () => import("./../../src/docs/docs/what-and-why.mdx" /* webpackChunkName: "component---src-docs-docs-what-and-why-mdx" */),
  "component---src-docs-main-index-mdx": () => import("./../../src/docs/main/index.mdx" /* webpackChunkName: "component---src-docs-main-index-mdx" */),
  "component---src-docs-try-index-mdx": () => import("./../../src/docs/try/index.mdx" /* webpackChunkName: "component---src-docs-try-index-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

