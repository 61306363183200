export default {
  base: "vs-dark",
  inherit: true,
  rules: [
    {
      foreground: "5c6370",
      token: "comment",
    },
    {
      foreground: "dcdfe4",
      token: "variable.parameter.function",
    },
    {
      foreground: "c678dd",
      token: "keyword",
    },
    {
      foreground: "e06c75",
      token: "variable",
    },
    {
      foreground: "61afef",
      token: "entity.name.function",
    },
    {
      foreground: "61afef",
      token: "meta.require",
    },
    {
      foreground: "61afef",
      token: "support.function.any-method",
    },
    {
      foreground: "e5c07b",
      token: "support.class",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.class",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.type.class",
    },
    {
      foreground: "e5c07b",
      token: "meta.class",
    },
    {
      foreground: "61afef",
      token: "keyword.other.special-method",
    },
    {
      foreground: "c678dd",
      token: "storage",
    },
    {
      foreground: "61afef",
      token: "support.function",
    },
    {
      foreground: "98c379",
      token: "string",
    },
    {
      foreground: "e5c07b",
      token: "constant.numeric",
    },
    {
      foreground: "e5c07b",
      token: "none",
    },
    {
      foreground: "e5c07b",
      token: "none",
    },
    {
      foreground: "e5c07b",
      token: "constant",
    },
    {
      foreground: "e06c75",
      token: "entity.name.tag",
    },
    {
      foreground: "e5c07b",
      token: "entity.other.attribute-name",
    },
    {
      foreground: "e5c07b",
      token: "entity.other.attribute-name.id",
    },
    {
      foreground: "e5c07b",
      token: "punctuation.definition.entity",
    },
    {
      foreground: "c678dd",
      token: "meta.selector",
    },
    {
      foreground: "61afef",
      token: "markup.heading punctuation.definition.heading",
    },
    {
      foreground: "61afef",
      token: "entity.name.section",
    },
    {
      foreground: "c678dd",
      token: "markup.bold",
    },
    {
      foreground: "c678dd",
      token: "punctuation.definition.bold",
    },
    {
      foreground: "c678dd",
      token: "markup.italic",
    },
    {
      foreground: "c678dd",
      token: "punctuation.definition.italic",
    },
    {
      foreground: "98c379",
      token: "markup.raw.inline",
    },
    {
      foreground: "98c379",
      token: "meta.link",
    },
    {
      foreground: "98c379",
      token: "markup.quote",
    },
    {
      foreground: "dcdfe4",
      token: "source.java meta.class.java meta.method.java",
    },
    {
      foreground: "dcdfe4",
      token: "source.java meta.class.java meta.class.body.java",
    },
    {
      foreground: "e06c75",
      token: "source.js meta.function.js variable.parameter.function.js",
    },
    {
      foreground: "e06c75",
      token: "source.js variable.other.readwrite.js",
    },
    {
      foreground: "dcdfe4",
      token: "source.js variable.other.object.js",
    },
    {
      foreground: "e06c75",
      token:
        "source.js meta.function-call.method.js variable.other.readwrite.js",
    },
    {
      foreground: "e06c75",
      token: "source.js meta.block.js variable.other.readwrite.js",
    },
    {
      foreground: "dcdfe4",
      token: "source.js meta.block.js variable.other.object.js",
    },
    {
      foreground: "dcdfe4",
      token:
        "source.js meta.block.js meta.function-call.method.js variable.other.readwrite.js",
    },
    {
      foreground: "dcdfe4",
      token: "source.js meta.function-call.method.js variable.function.js",
    },
    {
      foreground: "61afef",
      token: "source.js meta.property.object.js entity.name.function.js",
    },
    {
      foreground: "dcdfe4",
      token: "source.js support.constant.prototype.js",
    },
    {
      foreground: "98c379",
      token: "markup.inserted",
    },
    {
      foreground: "e06c75",
      token: "markup.deleted",
    },
    {
      foreground: "e5c07b",
      token: "markup.changed",
    },
    {
      foreground: "98c379",
      token: "string.regexp",
    },
    {
      foreground: "56b6c2",
      token: "constant.character.escape",
    },
    {
      foreground: "dcdfe4",
      background: "e06c75",
      token: "invalid.illegal",
    },
    {
      foreground: "dcdfe4",
      background: "e5c07b",
      token: "invalid.broken",
    },
    {
      foreground: "dcdfe4",
      background: "e5c07b",
      token: "invalid.deprecated",
    },
    {
      foreground: "dcdfe4",
      background: "c678dd",
      token: "invalid.unimplemented",
    },
  ],
  colors: {
    "editor.foreground": "#dcdfe4",
    "editor.background": "#13161f",
    "editor.selectionBackground": "#474e5d",
    "editor.lineHighlightBackground": "#13161f",
    "editorCursor.foreground": "#a3b3cc",
    "editorWhitespace.foreground": "#13161f",
    "editor.selectionHighlightBorder": "#474e5d",
  },
};
